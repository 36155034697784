<template>
  <div class="content_container consulting request_more">
    <div class="request_title">
      <h1>거래중개 컨설팅 요청</h1>
      <h2>현재 진행 중인 거래가 있으신가요?</h2>
      <p>
        거래 진척이 잘 안되거나 어려움이 있으신 고객께서는 이곳에 등록하시고
        메타바이셀 전문 컨설턴트의 도움을 받으세요.
      </p>
    </div>

    <div class="fill">
      <div class="fill_text">
        <h1>제목 <span class="star">*</span></h1>
        <input
          type="text"
          placeholder="제목을 입력해주세요"
          v-model="title"
          id="title"
        />
      </div>
      <div class="fill_explain">
        <h1>내용 <span class="star">*</span></h1>
        <textarea
          placeholder="직접 입력해주세요"
          v-model="content"
          id="content"
        ></textarea>
      </div>
      <div class="expose">
        <h1>문의 공개</h1>
        <div class="switch_toggle">
          <input type="checkbox" id="active" v-model="exposure_yn" />
          <label for="active"></label>
        </div>
      </div>
    </div>

    <div class="btn_wrap">
      <button class="common_button" @click="submit">작성하기</button>
      <button class="sub_button" @click="this.$router.go(-1)">이전</button>
    </div>
  </div>
</template>

<script>
import { postData } from "@/utils/postData";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // 보낼 데이터
      title: "",
      content: "",
      exposure_yn: true,
    };
  },
  mixins: [postData],
  computed: {
    ...mapState("access_token", ["access_token"]),
  },
  methods: {
    submit() {
      // 필수 입력 체크
      if (!this.title) {
        this.$store.commit("alert", "제목을 입력해주세요.");
        document.getElementById("title").focus();
      } else if (!this.content) {
        this.$store.commit("alert", "내용을 입력해주세요.");
        document.getElementById("content").focus();
      } else {
        const formData = new FormData();
        formData.append("access_token", this.access_token); // 유저 토큰 (*)
        formData.append("division", "거래중개 컨설팅"); // 문의 유형 (*)
        formData.append("title", this.title); // 상품명 (*)
        formData.append("content", this.content); // 상품설명 (*)
        formData.append("exposure_yn", this.exposure_yn); // 공개여부 (*)

        this.postData("/saveInquire", formData, () => {
          this.$store.commit("alert", "문의가 등록되었습니다");
          this.$router.go(-1);
        });
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/layout_request.css">
</style>